import { EButtonFill, EButtonType } from '../button/button.enums';

export const CGDPRConfigList = {
    buttons: {
        accept: {
            text: 'Accept',
            type: EButtonType.StandardShort,
            colorType: EButtonFill.Blue
        },
        reject: {
            text: 'Reject',
            type: EButtonType.StandardShort,
            colorType: EButtonFill.White
        }
    }
};
