<div class="users-activity-list">
    <span [matMenuTriggerFor]="menu" #trigger="matMenuTrigger"></span>
    <mat-menu #menu="matMenu" class="users-activity-list--menu" xPosition="before">
        <mat-icon class="menu--close" matPrefix svgIcon="close-cross"></mat-icon>
        <div class="menu--body">
            <div class="menu--scroll-holder ps ps-show-always" [perfectScrollbar]
                 [disabled]="activity.users?.length < 5">
                <div class="menu--list-item" *ngFor="let user of activity.users">
                    <div class="menu--user-logo">
                        <div *ngIf="!user.photo">
                            <mat-icon matPrefix svgIcon="teacher-icon" *ngIf="user.user_type==='TUTOR'">
                            </mat-icon>
                            <mat-icon matPrefix svgIcon="user-icon" *ngIf="user.user_type==='STUDENT'">
                            </mat-icon>
                        </div>
                        <img *ngIf="user.photo" [src]="user.photo" alt="user-logo">
                    </div>
                    <span>{{ user.first_name }} {{ user.last_name }}</span>
                </div>
            </div>
        </div>
    </mat-menu>
</div>
