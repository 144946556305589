<div class="list-future-sessions-title">
    <div class="title">{{ "future_sessions.title" | translate }}</div>
</div>
<div *ngIf="!loader" class="list-future-sessions-body" [class.scroll]="(sessionsFiltering$ | async).length > 5"
     (scroll)="scrollHandler($event)">

    <div *ngFor="let session of (sessionsFiltering$ | async)" class="lesson-future-item"
         (click)="navigateToSession($event, session.lesson_token)">

        <div class="lesson-future-item-logo">
            <img *ngIf="session.tutor.photo" [src]="session.tutor.photo" alt="lesson-logo">
            <img *ngIf="!session.tutor.photo" src="assets/images/account-profile.png" alt="lesson-logo">
        </div>

        <div class="lesson-future-item-title">
            <div class="title">{{ getTitle(session.lesson_title) }}</div>
            <div class="date">{{ replaceDate(session.book_time) | date: "d MMMM h:mm a"}}</div>
        </div>

        <div *ngIf="session.tutor.id === userId" class="lesson-future-item-actions">
            <button class="btn-update" (click)="updateSession(session.id)">
                {{ "future_sessions.update" | translate }}
            </button>
            <button class="btn-delete" (click)="openDeleteSessionPopup(session.id)">
                {{ "future_sessions.delete" | translate }}
            </button>
        </div>

    </div>

    <div *ngIf="!(sessionsData$ | async).length" class="list-empty">
        <div class="list-empty-title">
            {{ "future_sessions.scheduleMessage" | translate }}
        </div>
        <div class="list-empty-action">
            <button [routerLink]="['/new-session']">
                {{ "future_sessions.schedule" | translate }}
            </button>
        </div>
    </div>

</div>

<div *ngIf="loader" class="container">
    <div class="row">
        <div class="col-md-12">
            <div class="preloader-block">
                <div class="spinner-border" role="status">
                    <span class="sr-only"></span>
                </div>
            </div>
        </div>
    </div>
</div>
