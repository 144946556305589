import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import * as moment from 'moment';

import { TimeLineService } from './timeLine.service';

declare const MediaRecorder;

@Injectable({
    providedIn: 'root'
})
export class WhiteBoardService {
    isRecordingSupported = true;

    mediaRecorder: any;
    startRecordingTime: any;
    timer: any;
    currentVideoStatus: any;
    public recordedBlobs = [];
    public codeForGoogle: Subject<string> = new Subject<string>();

    constructor(
        private http: HttpClient,
        private timeLineService: TimeLineService
    ) {
        this.checkRecordingSupported();
    }

    checkRecordingSupported(): void {
        if (typeof MediaRecorder === 'undefined' || !navigator.getUserMedia) {
            this.isRecordingSupported = false;
            return;
        }
        this.isRecordingSupported = true;
    }

    public startWhiteBoard(lessonId: string | number): Observable<any> {
        return this.http.post('/lesson/startWhiteBoard', { lesson_id: lessonId });
    }

    public stopWhiteBoard(lessonId: string | number): Observable<any> {
        return this.http.post('/lesson/stopWhiteBoard', { lesson_id: lessonId });
    }

    public getWhiteboardActions(lessonId): Observable<any> {
        const lessonData = new HttpParams().set('lesson_id', lessonId);
        return this.http.get<any>('/whiteboard', { params: lessonData });
    }

    public getWhiteboardPoints(lessonId): Observable<any> {
        const lessonData = new HttpParams().set('lesson_id', lessonId);
        return this.http.get<any>('/whiteboard/points', { params: lessonData });
    }

    public getWhiteboardPointsMessages(lessonId, num, page): Observable<any> {
        const lessonData = new HttpParams().set('lesson_id', lessonId).set('number', num).set('page', page);
        return this.http.get<any>('/whiteboard/points/messages', { params: lessonData });
    }

    public getWhiteboardImagesList(lessonId): Observable<any> {
        const lessonData = new HttpParams().set('lesson_id', lessonId);
        return this.http.get<any>('/whiteboard/uploads', { params: lessonData });
    }

    public uploadFileForWhiteboard(data: FormData): Observable<any> {
        return this.http.post<any>('/whiteboard/uploads', data);
    }

    public uploadFilePositionForWhiteboard(data: FormData): Observable<any> {
        return this.http.post<any>('/whiteboard/uploads', data);
    }

    public deleteImageFromWhiteboard(lessonId, fileId): Observable<any> {
        const lessonData = new HttpParams().set('lesson_id', lessonId).set('file_id', fileId);
        return this.http.delete<any>('/whiteboard/uploads', { params: lessonData });
    }

    public uploadFileForWhiteboardFromGoogleDrive(lessonId: string, fileId: string): Observable<any> {
        // const codeData = new HttpParams().set('lesson_id', lessonId).set('file_id',fileId);
        const formData = new FormData();
        formData.append('lesson_id', lessonId);
        formData.append('file_id', fileId);
        return this.http.post<any>('/whiteboard/google-drive', formData);
    }

    public getAccessTokenWhiteboardFromGoogleDrive(): Observable<any> {
        return this.http.get<any>('/whiteboard/google-drive/token');
    }

    public getGoogleAuthUrlWhiteboardFromGoogleDrive(): Observable<any> {
        return this.http.get<any>('/whiteboard/google-drive/auth-url');
    }

    public generateGoogleAuthTokenWhiteboardFromGoogleDrive(code: string): Observable<any> {
        // const codeData = new HttpParams().set('code', code);
        const formData = new FormData();
        formData.append('code', code);
        return this.http.post<any>('/whiteboard/google-drive/auth', formData);
    }


    public recordWhiteboardStream(lessonId, userId, canvas?): void {
        if (!this.isRecordingSupported) {
            return;
        }
        console.log('BEGINNING', canvas);
        this.recordedBlobs = [];
        const stream = (canvas as any).captureStream();
        const mediaSource = new MediaSource();
        mediaSource.addEventListener('sourceopen', handleSourceOpen, false);
        let sourceBuffer;

        function handleSourceOpen(event) {
            sourceBuffer = mediaSource.addSourceBuffer('video/webm;codecs=vp8');
        }

        const handleDataAvailable = (event) => {
            if (event.data && event.data.size > 0) {
                this.recordedBlobs.push(event.data);
            }
        };

        let options = { mimeType: 'video/webm;codecs=vp8' };
        try {
            this.mediaRecorder = new MediaRecorder(stream, options);
        } catch (e0) {
            console.log('Unable to create MediaRecorder with options Object: ', e0);
            try {
                options = { mimeType: 'video/webm;codecs=vp8' };
                this.mediaRecorder = new MediaRecorder(stream, options);
            } catch (e1) {
                console.log('Unable to create MediaRecorder with options Object: ', e1);
            }
        }
        this.mediaRecorder.ondataavailable = handleDataAvailable;
        this.mediaRecorder.start(10);
        this.startRecordingTime = moment().unix();

        // TODO need test on backend && dev
        this.timer = setTimeout(() => {
            this.stopWhiteboardRecording(lessonId, userId, false, canvas);
            clearTimeout(this.timer);
        }, 60000);
    }

    public stopWhiteboardRecording(lessonId, userId, isEndSession, canvas?): void {
        if (!this.isRecordingSupported) {
            return;
        }
        clearTimeout(this.timer);
        if (this.mediaRecorder) {
            if (this.mediaRecorder.state !== 'inactive') {
                console.log('STOPPED');
                this.currentVideoStatus = 'WHITEBOARD';
                clearTimeout(this.timer);

                const currentTime = moment().unix();
                const endDuration = this.timeLineService.getCurrentTime();
                const startDuration = endDuration - (currentTime - this.startRecordingTime);

                this.mediaRecorder.onstop = () => {
                    const blob = new Blob(this.recordedBlobs, { type: 'video/webm' });

                    // console.log(blob);
                    // const url = window.URL.createObjectURL(blob);
                    // const a = document.createElement('a');
                    // a.style.display = 'none';
                    // a.href = url;
                    // a.download = 'test.webm';
                    // document.body.appendChild(a);
                    // a.click();
                    // setTimeout(() => {
                    //     document.body.removeChild(a);
                    //     window.URL.revokeObjectURL(url);
                    // }, 100);

                    this.savingProcessCustomRecord(
                        blob,
                        currentTime,
                        startDuration,
                        endDuration,
                        lessonId,
                        userId,
                        this.currentVideoStatus,
                        isEndSession
                    );
                };
                this.mediaRecorder.stop();
                setTimeout(() => {
                    if (!isEndSession) {
                        this.recordWhiteboardStream(lessonId, userId, canvas);
                    }
                }, 100);
            }
        }
    }

    savingProcessCustomRecord(blob, currentTime, startDuration, endDuration, lessonId, userId, userType, isEndSession): void {
        const fileType = '.webm';
        const newFile = new File([blob], currentTime + fileType);
        this.startCustomRecord(
            newFile,
            startDuration,
            endDuration,
            lessonId,
            userId,
            isEndSession
        ).subscribe();
    }

    startCustomRecord(fileData, startDuration, endDuration, lessonId, userId, isEndSession): Observable<any> {
        const formData = new FormData();
        formData.append('lesson_id', lessonId);
        formData.append('file_data', fileData);
        // formData.append('user_id', userId);
        formData.append('start_duration', startDuration);
        formData.append('end_duration', endDuration);
        formData.append('lesson_recorder', 'WHITEBOARD');
        formData.append('lesson_record_type', 'VIDEO');
        // formData.append('is_end_session', isEndSession);
        return this.http.post('/lesson/recordFile', formData);
    }
}
