import {
    Component,
    Input,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
    selector: 'app-users-activity-list',
    templateUrl: './users-activity-list.component.html',
    styleUrls: ['./users-activity-list.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class UsersActivityListComponent {
    @Input() activity;
    @ViewChild('trigger', { read: MatMenuTrigger })
    public triggerMenu: MatMenuTrigger;

    openMenu() {
        this.triggerMenu.openMenu();
    }
}
