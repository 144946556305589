import { Component, OnInit, OnDestroy } from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-up-button',
    templateUrl: './up-button.component.html',
    styleUrls: ['./up-button.component.scss']
})
export class UpButtonComponent implements OnInit, OnDestroy {
    public unsubscribe$: Subject<void> = new Subject<void>();
    public show = false;

    ngOnInit() {
        fromEvent(window, 'scroll')
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => this.dealWithScroll(window.scrollY));
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    dealWithScroll(y: number) {
        this.show = y > 100;
    }

    scrollTop() {
        window.scrollTo(0, 0);
    }
}
