import { Component, Input, OnInit, ViewChild } from '@angular/core';

@Component({
    selector: 'app-tooltip',
    templateUrl: './tooltip.component.html',
    styleUrls: ['./tooltip.component.scss'],
})
export class TooltipComponent implements OnInit {
    @ViewChild('tooltip') tooltipControl: any;

    @Input() text: string;
    @Input() position:
        | 'TopCenter'
        | 'BottomCenter'
        | 'RightCenter'
        | 'RightBottom'
        | 'LeftCenter'
        | 'LeftBottom' = 'BottomCenter';
    @Input() isActive: boolean;
    @Input() isExtra: boolean;

    constructor() {
    }

    ngOnInit() {
        // setTimeout(() => {
        //     console.log(this.tooltipControl)
        //     this.tooltipControl.open(this.tooltipControl.element)
        // },1000)
    }

    openTooltip(e: any, mode?: any,) {
        // if (!this.isActive) {
        //     this.tooltipControl.close();
        //     return;
        // }
        if (mode === 'test') {
            if (e?.getAttribute('data-tooltip-id')) {
                this.tooltipControl?.close();
            } else {
                try {
                    this.tooltipControl?.open(e);
                } catch (e) {
                }
            }
        } else {
            if (e?.target.getAttribute('data-tooltip-id')) {
                this.tooltipControl?.close();
            } else {
                this.tooltipControl?.open(e.target);
            }
        }
    }

    closeTooltip() {
        this.tooltipControl?.close();
    }
}
