<div class="connection-auto-reload">
    <div class="connection-auto-reload-title modal-block__title">
        {{ "internetConnection.title" | translate }}
    </div>
    <div class="connection-auto-reload-subtitle modal-block__subtitle">
        <span [translate]="'internetConnection.reload'" [translateParams]="{ value: count }" ></span>
    </div>
    <div class="connection-auto-reload-progress">
        <mat-progress-spinner
            class="example-margin"
            color="primary"
            mode="determinate"
            [value]="count * 20">
        </mat-progress-spinner>
    </div>
</div>
