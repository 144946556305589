export const environment = {
    production: true,
    // api_url: 'https://api.sharpennotes.com/api',
    api_url: 'https://api-beta.sharpennotes.com/api',
    api_key: '47353851',
    ws_url: 'wss://api-beta.sharpennotes.com:443/ws/',
    white_board_url: 'wss://api-beta.sharpennotes.com:443/wsw/',

    app_secret_key:
        'kIc9FQkpJk93rIaMlcXqr3KLZIvn1ix8K2K0BGN8O3SRLDPFb68pMJ4xBdZJWVOSkxGC66Lfq7RcN2CrM5i49zZEg1L51vEfqhJ6mAXj6GIzUpquS0grH5l4sXO65PPS',
    // domain: 'https://api.sharpen-app.com',
    domain: 'https://app.sharpennotes.com',
    fbClientId: '304520160628523',
    widget_bundle: {
        styles: 'https://d3v9ffocs6jjrx.cloudfront.net/bundle.css',
        script: 'https://d3v9ffocs6jjrx.cloudfront.net/bundle.esm.js'
    },
    app_uuid: {
        curriculum: '3c3e2ce2-0596-46aa-94c3-98240d2bbe9e',
        tutorHive: '42f8fa56-6afc-4a42-8098-a9878551d64a',
    }
};
