import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { CGDPRConfigList } from '../gdpr-popup.config';

@Component({
    selector: 'app-gdpr-popup',
    templateUrl: './gdpr-popup.component.html',
    styleUrls: ['./gdpr-popup.component.scss']
})
export class GDPRPopupComponent {
    public readonly configList = CGDPRConfigList;

    constructor(
        private dialogRef: MatDialogRef<GDPRPopupComponent>
    ) {
    }

    changeGDPRStatus(status: boolean): void {
        this.dialogRef.close(status);
    }
}
