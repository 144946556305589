import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class StartPageTooltipsService {

    public tooltips = new BehaviorSubject<any>(null);
    public tooltipsArr = [];
    public startEvent = new BehaviorSubject<any>(false);
    public marker: boolean;

    constructor(private router: Router) {
        this.tooltips?.subscribe(e => {
            if (e != null) {
                this.tooltipsArr.push(e);
            }
            this.startEvent.subscribe(e => this.marker = e);
            if (this.tooltipsArr?.length === 5) {
                this.tooltipsArr?.forEach((item, index) => {
                    setTimeout(() => {
                        if (item && this.marker) {
                            item.openTooltip(item.tooltipControl.element, 'test');
                            setTimeout(() => {
                                item.closeTooltip();
                            }, 3000);
                        }
                        if (!this.marker) {
                            clearTimeout();
                        }
                    }, (index + 1) * 3000);
                });
            }
        });
    }
}
