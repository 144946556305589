<div #subscriberDiv
     [ngClass]="{'full-video-view': isFullScreen && !lessonState.fullScreenWhiteboardState.getValue()}"
     class="
    {{ screenShareMode ? 'subscriber-small-video' : 'subscriber-video-block' }}
    {{ showStream ? 'show-video' : 'hide-video' }}
    {{ isFullScreen ? 'is-full-screen' : '' }} {{ currentUserType }}">
</div>
<!--<div *ngIf="!isVideo" class="subscriber-video-block d-flex align-items-center justify-content-center">-->
<!--    <div class="profile">-->
<!--        <div class="profile__avatar" id='avatar' *ngIf="!user.photo" [ngStyle]="subscriberIconHeight">-->
<!--            <mat-icon [svgIcon]="'no_name'"></mat-icon>-->
<!--        </div>-->
<!--        <div class="profile__photo" *ngIf="user.photo">-->
<!--            <img src="{{user.photo}}">-->
<!--        </div>-->
<!--        <div class="profile__info">-->
<!--            {{user.first_name}} {{user.last_name}}-->
<!--        </div>-->
<!--    </div>-->
<!--</div>-->
