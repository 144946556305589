<div class="gdpr-popup">
    <div class="gdpr-popup-content">
        <div class="content-item title-item">
            Sharpen Notes – Terms and Privacy Policy
        </div>
        <div class="content-item">
            Sharpen Notes helps you learn through connecting people and sharing notes. Our Services can be used as a
            plug-in
            to Microsoft Teams allowing you to collaborate and use Sharpen Notes in your Teams and Teams
            videoconferences.
        </div>
        <div class="content-item">
            Use of Sharpen Notes requires a Sharpen Notes Account.
        </div>
        <div class="content-item">
            By downloading Sharpen Notes, creating an account and integrating Sharpen Notes with Microsoft Teams you
            agree
            to the <a target="_blank" href="https://sharpennotes.com/terms-of-use">Sharpen Notes Terms and
            Conditions</a>.
        </div>
        <div class="content-item title-item">
            Privacy Policy
        </div>
        <div class="content-item">
            Sharpen Notes is committed to protecting and respecting your privacy, and we'll only use your personal
            information to provide the Sharpen Notes services and for the reasons set-out in our <a target="_blank"
                                                                                                    href="https://sharpennotes.com/privacy-policy">Privacy
            Policy</a>.
        </div>
        <div class="content-item">
            When you use Sharpen Notes in conjunction with your Microsoft Account and Microsoft Teams, we will receive
            information about you direct from Microsoft. This will typically include basic information about your
            Microsoft
            Account, your Teams chats, your calendars and such other information provided by Microsoft to allow Sharpen
            Notes to work in conjunction with Microsoft Teams.
        </div>
        <div class="content-item title-item">
            Acceptance
        </div>
        <div class="content-item">
            By clicking accept below, you agree to the <a target="_blank" href="https://sharpennotes.com/terms-of-use">Sharpen
            Notes Terms</a> and acknowledge that Sharpen Notes will
            store and process you personal information in accordance with the
            <a target="_blank" href="https://sharpennotes.com/privacy-policy">Sharpen
                Notes Privacy Policy</a>.
        </div>
    </div>
    <div class="gdpr-popup-actions">
        <app-button [buttonParameters]="configList.buttons.reject"
                    (click)="changeGDPRStatus(false)">
        </app-button>
        <app-button [buttonParameters]="configList.buttons.accept"
                    (click)="changeGDPRStatus(true)">
        </app-button>
    </div>
</div>
