<div class="messages">
    <div class="messages-title modal-block__title">
        Please note
    </div>
    <div class="messages-text modal-block__subtitle">
        {{ message }}
    </div>
    <div class="messages-actions">
        <app-button [buttonParameters]="configList.buttons.close" (click)="closeDialog()"></app-button>
    </div>
</div>
