// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    api_url: 'https://api.sharpennotes.com/api',
    // api_url: 'https://api.sharpennotes.com',
    api_key: '47592691',
    ws_url: 'wss://api.sharpennotes.com:443/ws/',
    white_board_url: 'wss://api.sharpennotes.com:443/wsw/',
    app_secret_key:
        'kIc9FQkpJk93rIaMlcXqr3KLZIvn1ix8K2K0BGN8O3SRLDPFb68pMJ4xBdZJWVOSkxGC66Lfq7RcN2CrM5i49zZEg1L51vEfqhJ6mAXj6GIzUpquS0grH5l4sXO65PPS',
    domain: 'http://localhost:4200',
    fbClientId: '304520160628523',
    widget_bundle: {
        styles: 'https://diu6s6kn1snxn.cloudfront.net/bundle.css',
        script: 'https://diu6s6kn1snxn.cloudfront.net/bundle.esm.js'
    },
    app_uuid: {
        curriculum: '308b330b-87dc-4f13-9a23-6d13b684e47a',
        tutorHive: '1dc7aa3e-6950-4951-96d5-cf2c9c195bfa',
    }
};
//
// export const environment = {
//     production: false,
//     // api_url: 'https://api.sharpennotes.com/api',
//     api_url: 'https://api-beta.sharpennotes.com/api',
//     api_key: '46749012',
//     ws_url: 'wss://api-beta.sharpennotes.com:443/ws/',
//     white_board_url: 'wss://api-beta.sharpennotes.com:443/wsw/',
//
//     app_secret_key:
//         'kIc9FQkpJk93rIaMlcXqr3KLZIvn1ix8K2K0BGN8O3SRLDPFb68pMJ4xBdZJWVOSkxGC66Lfq7RcN2CrM5i49zZEg1L51vEfqhJ6mAXj6GIzUpquS0grH5l4sXO65PPS',
//     // domain: 'https://api.sharpen-app.com',
//     domain: 'https://app.sharpennotes.com',
//     fbClientId: '304520160628523',
// };

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
