import { EInputType } from '../input/input.enums';

export const CConfirmationPageConfigList = {
    inputs: {
        sessionLink: {
            type: EInputType.SessionLink,
            label: 'lesson.sessionLink',
            placeholder: ''
        }
    }
};
