import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'unique',
})
export class UniqueFilterPipe implements PipeTransform {
    transform(value: any, args?: any): any {
        const uniqueArray = Array.from(new Set(value));
        return uniqueArray;
    }
}
