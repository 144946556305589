import { Injectable, OnDestroy } from '@angular/core';
import { interval, Subject } from 'rxjs';
import { skip, takeUntil } from 'rxjs/operators';

import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import {
    ConnectionLostComponent,
    ENetworkStatus
} from '../shared/components/connection-lost/connection-lost.component';
import { SpeedTestService } from 'ng-speed-test';
import {
    ConnectionAutoReloadComponent
} from '../shared/components/connection-auto-reload/connection-auto-reload.component';

@Injectable({
    providedIn: 'root'
})
export class NetworkConnectionService implements OnDestroy {
    public destroy$: Subject<void> = new Subject<void>();
    public dialogRef: MatDialogRef<ConnectionLostComponent> = null;

    public isOnline = true;

    public destroyCountLostConnection$: Subject<void> = new Subject<void>();

    constructor(
        private speedTestService: SpeedTestService,
        private dialog: MatDialog
    ) {
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    startCountLostConnectionDuration(): void {
        interval(10000)
            .pipe(takeUntil(this.destroyCountLostConnection$))
            .subscribe({
                next: () => {
                    this.dialog.closeAll();
                    this.dialog.open(ConnectionAutoReloadComponent);
                    this.destroyCountLostConnection$.next();
                }
            });
    }

    connectionLost(): void {
        this.speedTestService.isOnline()
            .pipe(skip(1), takeUntil(this.destroy$))
            .subscribe({
                next: (isOnline) => {
                    this.isOnline = isOnline;
                    this.openConnectionLostDialog(isOnline ? ENetworkStatus.Online : ENetworkStatus.Offline);
                    if (isOnline) {
                        this.destroyCountLostConnection$.next();
                        return;
                    }
                    this.startCountLostConnectionDuration();
                },
                error: (error) => {
                    console.log('connectionLost: offline', error);
                    if (!this.dialogRef) {
                        return;
                    }
                    this.dialogRef.close();
                }
            });
    }

    openConnectionLostDialog(state: string): void {
        this.dialog.closeAll();
        this.dialogRef = this.dialog.open(ConnectionLostComponent, {
            data: state
        });
    }
}
