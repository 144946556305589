import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { environment } from '../../environments/environment';

// Material
import { MAT_RIPPLE_GLOBAL_OPTIONS, MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCardModule } from '@angular/material/card';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { PortalModule } from '@angular/cdk/portal';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

// PerfectScrollbar
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

// Social auth
import { SocialAuthServiceConfig } from 'angularx-social-login';
import { FacebookLoginProvider } from 'angularx-social-login';
import { NgxLinkifyjsModule } from 'ngx-linkifyjs';

// Directives
import { TextareaNewLineDirective } from './directives/textarea-new-line.directive';
import { StopPropagationDirective } from './directives/stop-propagation.directive';
import { NoteOwnerStatusDirective } from './directives/note-owner-status.directive';
import { InputErrorMsgDirective, ErrorMsgComponent } from './directives/input-error-msg.directive';

// Pipes
import { NumberToTime } from './pipes/number-to-time.pipe';
import { DateAgoPipe } from './pipes/date-ago.pipe';
import { SortByPipe } from './pipes/sortBy.pipe';
import { UniqueFilterPipe } from './pipes/unique-filter.pipe';

// Components
import { FilePreviewComponent } from './components/file-preview/file-preview.component';
import { MatDialogModule } from '@angular/material/dialog';
import { UploadFileStatusComponent } from './components/upload-file-status/upload-file-status.component';
import { UploadFilesComponent } from './components/upload-files/upload-files.component';
import { UsersActivityListComponent } from './components/users-activity-list/users-activity-list.component';
import { CommentComponent } from './components/comment/comment.component';
import { TooltipModule } from '@syncfusion/ej2-angular-popups';
import { CenteredCardComponent } from './components/centered-card/centered-card.component';
import { NoAuthHeaderComponent } from './components/no-auth-header/no-auth-header.component';
import { CenteredCardBtnsComponent } from './components/centered-card-btns/centered-card-btns.component';
import { ConfirmationPageComponent } from './components/confirmation-page/confirmation-page.component';
import { HeaderComponent } from './components/header/header.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ChangePhotoComponent } from './components/change-photo/change-photo.component';
import { ChangePhotoDialogComponent } from './components/change-photo/change-photo-dialog/change-photo-dialog.component';
import { PopUpComponent } from './components/pop-up/pop-up.component';
import { FooterComponent } from './components/footer/footer.component';
import { TooltipComponent } from './components/tooltip/tooltip.component';
import { UpButtonComponent } from './components/up-button/up-button.component';
import { PublisherComponent } from '../entities/active-lesson/video-stream/publisher/publisher.component';
import { SubscriberComponent } from '../entities/active-lesson/video-stream/subscriber/subscriber.component';
import { ButtonComponent } from './components/button/component/button.component';
import { InputComponent } from './components/input/component/input.component';
import { NotificationComponent } from './components/notification/component/notification.component';
import { ListFutureSessionsComponent } from './components/list-future-sessions/list-future-sessions.component';
import { DeleteSessionPopupComponent } from './components/delete-session-popup/delete-session-popup.component';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import { ConnectionLostComponent } from './components/connection-lost/connection-lost.component';
import { IntersectionObserverDirective } from './directives/intersection-observer/intersection-observer.directive';
import { MessagesComponent } from './components/messages/component/messages.component';
import { GDPRPopupComponent } from './components/gdpr-popup/component/gdpr-popup.component';
import { ConnectionAutoReloadComponent } from './components/connection-auto-reload/connection-auto-reload.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
};

export const translationConfig = {
    loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) => new TranslateHttpLoader(http),
        deps: [HttpClient],
    },
    defaultLanguage: 'en',
};

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule.forRoot(translationConfig),
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatChipsModule,
    MatIconModule,
    MatButtonModule,
    MatDatepickerModule,
    MatMenuModule,
    MatSlideToggleModule,
    MatProgressBarModule,
    PerfectScrollbarModule,
    FormsModule,
    MatTooltipModule,
    RouterModule,
    MatSelectModule,
    PortalModule,
    MatNativeDateModule,
    NgxLinkifyjsModule,
    TooltipModule,
    VirtualScrollerModule,
    MatProgressSpinnerModule
  ],
    declarations: [
        TextareaNewLineDirective,
        FilePreviewComponent,
        UploadFileStatusComponent,
        CommentComponent,
        UploadFilesComponent,
        NumberToTime,
        UsersActivityListComponent,
        DateAgoPipe,
        StopPropagationDirective,
        NoteOwnerStatusDirective,
        SortByPipe,
        CenteredCardComponent,
        CenteredCardBtnsComponent,
        NoAuthHeaderComponent,
        InputErrorMsgDirective,
        ErrorMsgComponent,
        ConfirmationPageComponent,
        HeaderComponent,
        ChangePhotoComponent,
        ChangePhotoDialogComponent,
        PopUpComponent,
        FooterComponent,
        UniqueFilterPipe,
        TooltipComponent,
        UpButtonComponent,
        PublisherComponent,
        SubscriberComponent,
        ButtonComponent,
        InputComponent,
        NotificationComponent,
        ListFutureSessionsComponent,
        DeleteSessionPopupComponent,
        ConnectionLostComponent,
        IntersectionObserverDirective,
        MessagesComponent,
        GDPRPopupComponent,
        ConnectionAutoReloadComponent
    ],
    exports: [
        ReactiveFormsModule,
        TooltipModule,
        TranslateModule,
        MatFormFieldModule,
        MatInputModule,
        MatCheckboxModule,
        MatChipsModule,
        MatIconModule,
        MatButtonModule,
        MatMenuModule,
        MatAutocompleteModule,
        MatCardModule,
        MatToolbarModule,
        MatRadioModule,
        MatSlideToggleModule,
        TextareaNewLineDirective,
        FilePreviewComponent,
        MatDialogModule,
        MatProgressBarModule,
        UploadFileStatusComponent,
        CommentComponent,
        UploadFilesComponent,
        NumberToTime,
        DateAgoPipe,
        UsersActivityListComponent,
        PerfectScrollbarModule,
        StopPropagationDirective,
        NoteOwnerStatusDirective,
        SortByPipe,
        FormsModule,
        CenteredCardComponent,
        CenteredCardBtnsComponent,
        MatTooltipModule,
        NoAuthHeaderComponent,
        InputErrorMsgDirective,
        ErrorMsgComponent,
        ConfirmationPageComponent,
        HeaderComponent,
        MatDatepickerModule,
        MatSelectModule,
        PortalModule,
        MatNativeDateModule,
        HeaderComponent,
        ChangePhotoComponent,
        PopUpComponent,
        NgxLinkifyjsModule,
        FooterComponent,
        UniqueFilterPipe,
        TooltipComponent,
        UpButtonComponent,
        PublisherComponent,
        SubscriberComponent,
        ButtonComponent,
        InputComponent,
        NotificationComponent,
        ListFutureSessionsComponent,
        VirtualScrollerModule,
        IntersectionObserverDirective
    ],
    entryComponents: [ErrorMsgComponent],
})
export class SharedModule {
    constructor(translate: TranslateService) {
        translate.setDefaultLang('en');
    }

    static forRoot(): ModuleWithProviders {
        return {
            ngModule: SharedModule,
            providers: [
                {
                    provide: PERFECT_SCROLLBAR_CONFIG,
                    useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
                },
                {
                    provide: MAT_RIPPLE_GLOBAL_OPTIONS,
                    useValue: {
                        disabled: true,
                        animation: {
                            enterDuration: 0,
                            exitDuration: 0,
                        },
                    },
                },
                {
                    provide: 'SocialAuthServiceConfig',
                    useValue: {
                        autoLogin: false,
                        providers: [
                            {
                                id: FacebookLoginProvider.PROVIDER_ID,
                                provider: new FacebookLoginProvider(
                                    environment.fbClientId
                                ),
                            },
                        ],
                    } as SocialAuthServiceConfig,
                },
            ],
        };
    }
}
