import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-pop-up',
    templateUrl: './pop-up.component.html',
    styleUrls: ['./pop-up.component.scss']
})
export class PopUpComponent {

    constructor(
        public dialogRef: MatDialogRef<PopUpComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
    }

    onNoClick(status: boolean): void {
        this.dialogRef.close(status);
    }

}
