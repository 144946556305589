import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import { CSvgIconsList } from '../constants/constants';

@Injectable({
    providedIn: 'root'
})
export class SvgService {

    public readonly svgIconsList = CSvgIconsList;

    constructor(
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer
    ) {
    }

    initSvg(): void {
        this.svgIconsList.forEach((item: { name: string, icon: string }) => {
            this.matIconRegistry.addSvgIcon(
                item.name,
                this.domSanitizer.bypassSecurityTrustResourceUrl(
                    `/assets/images/svg/${ item.icon }.svg`
                )
            );
        });
    }
}
