import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
    public openNotificationState$: BehaviorSubject<null | string> = new BehaviorSubject<string | null>(null);

    openNotificationBlock(value: string): void {
        this.openNotificationState$.next(value);
    }

    resetNotificationBlock(): void{
        this.openNotificationState$.next(null);
    }
}
