import { Component, OnDestroy } from '@angular/core';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-page-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnDestroy {
    public unsubscribe$: Subject<void> = new Subject<void>();
    public openInNewWindow = false;

    constructor(public router: Router) {
        router.events
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((e: RouterEvent) => {
                if (e instanceof NavigationEnd) {
                    this.openInNewWindow = e.url.includes('lesson/active') || e.url.includes('ended-lesson');
                }
            });
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    getDate(): Date {
        return new Date();
    }
}
