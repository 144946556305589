import { EButtonFill, EButtonType } from '../button/button.enums';

export const CMessagesConfigList = {
    buttons: {
        close: {
            type: EButtonType.StandardMedium,
            text: 'Close',
            colorType: EButtonFill.Blue
        }
    }
};
