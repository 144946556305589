import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import * as OT from '@opentok/client';

@Injectable(
    { providedIn: 'any' },
)
export class LessonStateService {

    public lessonParticipants: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public participantsList: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public lessonStart: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
    public whiteBoardSession: Subject<boolean> = new Subject<boolean>();
    public publisherForStudents: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    public lessonType: Subject<string> = new Subject<string>();
    public lessonType$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    public isSecondConnection: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public currentUserType: Subject<string> = new Subject<string>();
    public isCameraIcon: Subject<boolean> = new Subject<boolean>();
    public isPublisherShow: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    public isSmallVideo: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public isFullScreen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public startScreenShare: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    public lessonFinish: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
    public lessonFinishEd: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
    public session: BehaviorSubject<OT.Session> = new BehaviorSubject<OT.Session>(null);
    public lessonStatus: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    public showStartButton: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    public isStartLessonDisabled: Subject<boolean> = new Subject<boolean>();
    public showStopButton: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
    public isFinishLessonDisabled: Subject<boolean> = new Subject<boolean>();
    public showWhiteBoard: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
    public statusWhiteBoard: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
    //
    public isCameraOn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
    public isMute: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
    public isScreenShare: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public showTutorButton: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
    public showActionButtonForStudents: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

    public streamsClone: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    public fullScreenWhiteboardState: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

    public changeLessonStatus(e): void {
        this.lessonStatus.next(e);
    }
}
