import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { IMessagesData } from '../messages.interfaces';
import { CMessagesConfigList } from '../messages.config';

@Component({
    selector: 'app-messages',
    templateUrl: './messages.component.html',
    styleUrls: ['./messages.component.scss']
})
export class MessagesComponent implements OnInit {
    public readonly configList = CMessagesConfigList;
    public message: string;

    constructor(
        private dialogRef: MatDialogRef<MessagesComponent>,
        @Inject(MAT_DIALOG_DATA) public data: IMessagesData
    ) {
    }

    ngOnInit(): void {
        this.setMessage();
    }

    setMessage(): void {
        this.message = this.data.text;
    }

    closeDialog(): void {
        this.dialogRef.close();
    }
}
