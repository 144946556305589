import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, combineLatest, of, Subject } from 'rxjs';
import { switchMap, take, takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';

import { LessonsService } from '../../../services/lessons.service';
import { IFutureLesson } from '../../../models/future-lessons.interface';
import { UserService } from '../../../services/user.service';
import { NotificationService } from '../../../services/notification.service';
import { ENotificationType } from '../notification/notification.enums';
import { DeleteSessionPopupComponent } from '../delete-session-popup/delete-session-popup.component';

const defaultSessionCount = 15;
const defaultScrollHeight = 950;

@Component({
    selector: 'app-list-future-sessions',
    templateUrl: './list-future-sessions.component.html',
    styleUrls: ['./list-future-sessions.component.scss']
})
export class ListFutureSessionsComponent implements OnInit, OnDestroy {
    public sessionsData$: BehaviorSubject<IFutureLesson[]> = new BehaviorSubject<IFutureLesson[]>(null);
    public sessionsFiltering$: BehaviorSubject<IFutureLesson[]> = new BehaviorSubject<IFutureLesson[]>(null);
    public loader = true;
    public scrollHeight = defaultScrollHeight;
    public counterViewSessions$ = new BehaviorSubject<number>(defaultSessionCount);
    public counterLoads$ = new BehaviorSubject<number>(1);
    public unsubscribe$: Subject<void> = new Subject<void>();
    public userId: number;

    constructor(
        private lessonsService: LessonsService,
        private router: Router,
        private dialog: MatDialog,
        private userService: UserService,
        private notificationService: NotificationService
    ) {
    }

    ngOnInit(): void {
        this.getFutureSessionsList();
        this.notificationService.resetNotificationBlock();
        this.initSessionsFiltering();
        this.userId = this.userService.getUser()?.id;
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    initSessionsFiltering(): void {
        combineLatest([
            this.sessionsData$,
            this.counterViewSessions$
        ]).pipe(
            takeUntil(this.unsubscribe$),
            switchMap(([
                           sessionsDataSubject,
                           counterViewSessionsSubject
                       ]) => of(sessionsDataSubject?.slice(0, counterViewSessionsSubject)))
        )
            .subscribe((res: IFutureLesson[]) => {
                if (res && typeof res === 'object') {
                    const resSorted = res.sort((a, b) => {
                        return new Date(a.book_time).getTime() - new Date(b.book_time).getTime();
                    });
                    this.sessionsFiltering$.next(resSorted);
                }
            });
    }

    scrollHandler(event: Event): void {
        const scrollTop = (event.target as HTMLElement).scrollTop;
        if (scrollTop > this.scrollHeight) {
            this.scrollHeight = this.scrollHeight + 540;
            this.counterLoads$.next(this.counterLoads$.getValue() + 1);
            this.counterViewSessions$.next(defaultSessionCount * this.counterLoads$.getValue());
        }
    }

    getFutureSessionsList(): void {
        this.lessonsService.getFutureSessionsList().subscribe(result => {
            this.sessionsData$.next(result);
            this.loader = false;
        });
    }

    navigateToSession(event: Event, token: string): void {
        const target = event.target as HTMLElement;
        if (target.localName !== 'button') {
            const userId = this.userService.getUser()?.id;
            this.router.navigate(['/lesson/devices-settings'], {
                queryParams: {
                    key: token,
                    person_id: userId
                },
            });
        }
    }

    updateSession(id: string | number): void {
        this.router.navigate(['/new-session'], {
            queryParams: {
                lesson_id: id
            }
        });
    }

    openDeleteSessionPopup(id: string | number): void {
        const dialogRef = this.dialog.open(DeleteSessionPopupComponent);
        dialogRef.afterClosed()
            .pipe(take(1))
            .subscribe(state => {
                if (!state) {
                    return;
                }
                this.deleteSession(id);
            });
    }

    deleteSession(id: string | number): void {
        this.lessonsService.deleteLesson(id).subscribe(res => {
            this.getFutureSessionsList();
            this.notificationService.openNotificationBlock(ENotificationType.SessionDeleted);
        });
    }

    getTitle(title: string): string {
        const innerWidth = window.innerWidth;
        switch (true) {
            case innerWidth <= 1090:
                return title.length > 25 ? title.substr(0, 22) + '...' : title;
            default:
                return title.length > 40 ? title.substr(0, 37) + '...' : title;
        }
    }

    replaceDate(data: Date): string {
        return data.toString().replace(/-/g, '/');
    }
}
