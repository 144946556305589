export enum ENotificationType {
    CopyLink = 'copy-link',
    UserRegistered = 'user-registered',
    InviteSuccess = 'invite-success',
    InviteError = 'invite-error',
    SessionIdInvalid = 'session-id-invalid',
    SessionDeleted = 'session-deleted'
}

export enum ENotificationTranslateKeys {
    CopyLink = 'notification.copyLink',
    UserRegistered = 'notification.userRegistered',
    InviteSuccess = 'notification.inviteSuccess',
    InviteError = 'notification.inviteError',
    SessionIdInvalid = 'notification.sessionIdInvalid',
    SessionDeleted = 'notification.sessionDeleted'
}
