import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'sortBy',
})
export class SortByPipe implements PipeTransform {
    transform(array: any, field: string, direction: string): any[] {
        if (!Array.isArray(array)) {
            return;
        }
        array.sort((a: any, b: any) => {
            if (direction === 'desc') {
                return b[field] - a[field];
            } else {
                return a[field] - b[field];
            }
        });
        return array;
    }
}
