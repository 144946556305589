<div class="connection-lost">
    <div class="connection-lost-title modal-block__title">
        {{"internetConnection.title" | translate}}
    </div>
    <div class="connection-lost-subtitle modal-block__subtitle">
        <span *ngIf="ENetworkStatus.Online === data">{{"internetConnection.online" | translate}}</span>
        <span *ngIf="ENetworkStatus.Offline === data">{{"internetConnection.offline" | translate}}</span>
    </div>
    <div class="connection-lost-actions">
        <app-button *ngIf="ENetworkStatus.Online === data" [buttonParameters]="configList.closeButton" (click)="closeDialog()"></app-button>
<!--        <app-button *ngIf="ENetworkStatus.Offline === data" [buttonParameters]="configList.refreshButton" (click)="refreshPage()"></app-button>-->
        <app-button *ngIf="ENetworkStatus.Offline === data" [buttonParameters]="configList.closeButton" (click)="closeDialog()"></app-button>
    </div>
</div>
