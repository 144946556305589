import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { EButtonFill, EButtonType } from '../button/button.enums';

export enum ENetworkStatus {
    Offline = 'offline',
    Online = 'online'
}

@Component({
    selector: 'app-connection-lost',
    templateUrl: './connection-lost.component.html',
    styleUrls: ['./connection-lost.component.scss']
})
export class ConnectionLostComponent {
    public readonly ENetworkStatus = ENetworkStatus;
    public configList = {
        closeButton: {
            text: 'Close',
            type: EButtonType.StandardShort,
            colorType: EButtonFill.Blue
        },
        refreshButton: {
            text: 'Refresh Page',
            type: EButtonType.StandardShort,
            colorType: EButtonFill.Blue
        }
    };

    constructor(
        private dialogRef: MatDialogRef<ConnectionLostComponent>,
        @Inject(MAT_DIALOG_DATA) public data: string
    ) {
    }

    refreshPage(): void {
        window.location.reload();
        this.closeDialog();
    }

    closeDialog(): void {
        this.dialogRef.close();
    }
}
