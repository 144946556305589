<div class="delete-session-popup">
    <div class="delete-session-popup-title modal-block__title">
        Are you sure that you want to delete this session?
    </div>
    <div class="delete-session-popup-subtitle modal-block__subtitle">
        You will not be able to return it
    </div>
    <div class="delete-session-popup-actions">
        <app-button [buttonParameters]="configList.buttons.delete" (click)="closeDialog(true)"></app-button>
        <app-button [buttonParameters]="configList.buttons.cancel" (click)="closeDialog(false)"></app-button>
    </div>
</div>
