import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

interface ILogs {
    lesson_id: number;
    logs: string;
    error: any;
}

@Injectable({
    providedIn: 'root'
})
export class LogsService {

    constructor(private http: HttpClient) {}

    sendLogs(logsBody: ILogs): void {
        this.http.post('/logs', logsBody).subscribe();
    }
}
