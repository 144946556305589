import { Component, OnInit } from '@angular/core';
import {WhiteBoardService} from "../../../../services/white-board.service";
import {environment} from "../../../../../environments/environment.prod";

@Component({
  selector: 'app-google-receive-code',
  templateUrl: './google-receive-code.component.html',
  styleUrls: ['./google-receive-code.component.scss']
})
export class GoogleReceiveCodeComponent implements OnInit {

  constructor(private wbService: WhiteBoardService) { }

  ngOnInit(): void {
      const link = window.location.href
      const queryParams = link.split('?')[1]
      const code = new URLSearchParams(queryParams)
      const queryCode = {
          codeForGoogle: code.get("code")
      }
      window.opener.postMessage(queryCode,environment.domain)
      setTimeout(() => {
          window.close()
      }, 500)
  }
}
