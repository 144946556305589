import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { EButtonFill, EButtonType } from '../button/button.enums';

@Component({
    selector: 'app-delete-session-popup',
    templateUrl: './delete-session-popup.component.html',
    styleUrls: ['./delete-session-popup.component.scss']
})
export class DeleteSessionPopupComponent {
    public configList = {
        buttons: {
            delete: {
                text: 'Delete',
                type: EButtonType.StandardMedium,
                colorType: EButtonFill.Blue
            },
            cancel: {
                text: 'Cancel',
                type: EButtonType.StandardMedium,
                colorType: EButtonFill.White
            }
        }
    };

    constructor(private dialogRef: MatDialogRef<DeleteSessionPopupComponent>) {
    }

    closeDialog(state: boolean): void {
        this.dialogRef.close(state);
    }
}
