import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { interval, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-connection-auto-reload',
    templateUrl: './connection-auto-reload.component.html',
    styleUrls: ['./connection-auto-reload.component.scss']
})
export class ConnectionAutoReloadComponent implements AfterViewInit, OnDestroy {

    public count = 5;

    public destroy$: Subject<void> = new Subject<void>();

    constructor(private dialogRef: MatDialogRef<ConnectionAutoReloadComponent>) {
    }

    ngAfterViewInit(): void {
        this.startCountDown();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.next();
    }

    startCountDown(): void {
        interval(1000)
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (iter) => {
                    this.count = this.count - 1;
                    if (iter < 5) return;
                    this.reloadPage();
                    this.destroy$.next();
                }
            });
    }

    reloadPage(): void {
        window.location.reload();
        this.closeDialog();
    }

    closeDialog(): void {
        this.dialogRef.close();
    }
}
