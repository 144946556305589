import { FormControl, ValidatorFn, FormGroup } from '@angular/forms';

/**
 * Custom validation functions
 */
export const customValidators = {
    requiredFileTypes(types: string[]): ValidatorFn {
        return (
            control: FormControl
        ): { [key: string]: { [key: string]: any } } | null => {
            const files: File[] = control.value;
            if (files?.length) {
                const errors = { filesTypesErrors: {} };

                files.forEach((file) => {
                    const splitter = file.name.split('.');
                    const extension = splitter.pop().toLowerCase();
                    if (!types.includes(extension)) {
                        errors.filesTypesErrors[file.name] = extension;
                    }
                });

                if (Object.values(errors.filesTypesErrors).length) {
                    return errors;
                }
                return null;
            }

            return null;
        };
    },

    requiredFileSize(allowedSize: number): ValidatorFn {
        return (
            control: FormControl
        ): { [key: string]: { [key: string]: any } } | null => {
            const files: File[] = control.value;
            if (files?.length) {
                const errors = { filesSizesErrors: {} };

                files.forEach((file) => {
                    const { name, size } = file;
                    if (size > allowedSize) {
                        errors.filesSizesErrors[name] = size;
                    }
                });

                if (Object.values(errors.filesSizesErrors).length) {
                    return errors;
                }
                return null;
            }

            return null;
        };
    },

    passwordsMismatch(original: string, repeated: string) {
        return (form: FormGroup) => {
            const password = form.get(original);
            const repeatPassword = form.get(repeated);

            if (
                repeatPassword?.errors &&
                password?.errors &&
                !repeatPassword.errors.mismatch
            ) {
                return;
            }

            if (
                password?.value &&
                repeatPassword?.value &&
                password?.value !== repeatPassword?.value
            ) {
                repeatPassword.setErrors({ mismatch: true });
            } else {
                const repeatPasswordErrors = repeatPassword.errors;
                delete repeatPasswordErrors?.mismatch;
                let isEmptyObjectErrors;
                try {
                    isEmptyObjectErrors = Object.keys(repeatPasswordErrors).length;
                } catch (error) {
                    isEmptyObjectErrors = null;
                }
                repeatPassword.setErrors(
                    isEmptyObjectErrors
                        ? {
                            ...repeatPassword.errors,
                        }
                        : null
                );
            }
        };
    }
};
