<div class="page-footer">
    <div class="container">
        <div class="row justify-content-between">
            <div class="page-footer-block">
                <div class="page-footer--copyright">
                    Copyright © {{ getDate() | date: 'y' }} Sharpen - All Rights Reserved.
                </div>
            </div>
            <div class="page-footer-block">
                <div class="page-footer--privacy-policy">
                    <a routerLink="/pages/privacy-policy" routerLinkActive="active-link" [target]="openInNewWindow ? '_blank' : '_self'">Privacy Policy</a>
                    <a routerLink="/pages/terms-of-use" routerLinkActive="active-link" [target]="openInNewWindow ? '_blank' : '_self'">Terms Of Use</a>
                    <a routerLink="/pages/cookie-policy" routerLinkActive="active-link" [target]="openInNewWindow ? '_blank' : '_self'">Cookie Policy</a>
                </div>
            </div>
        </div>
    </div>
</div>

